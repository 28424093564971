.searchWrapper {
  border-radius: 10px !important;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0;
}

.searchWrapper:hover {
  border-color: black;
}

.multiSelectContainer .searchBox {
  font-weight: bold;
  font-size: 1.1em;
  font-family: ProximaNova, ProximaNovaMedium, ProximaNovaSemibold, ProximaNovaSoftSemibold, ProximaNovaSoftBold, ProximaNovaSoftMedium, ProximaNovaSoftRegular;
  color: black !important;
  margin: 16.5px 7px !important;
  width: 90%;
}

.multiSelectContainer .checkbox {
  margin: 3px 0 0 0;
  width: 15%;
}

.multiSelectContainer .groupChildEle.option {
  padding: 10px 10px;
}

/* Edge 12-18 */
input::-ms-input-placeholder {
  color: black !important;
}

input::placeholder {
  color: black !important;
}

.multiSelectContainer ul {
  max-height: 55vh !important;
}

.optionListContainer {
  border: 0;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

/* text input field */
.MuiOutlinedInput-notchedOutline {
  height: 55px;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiInputBase-root {
  border-radius: 10px !important;
  font-weight: bold !important;
  font-size: 1.1em !important;
  font-family: ProximaNova, ProximaNovaMedium, ProximaNovaSemibold, ProximaNovaSoftSemibold, ProximaNovaSoftBold, ProximaNovaSoftMedium, ProximaNovaSoftRegular !important;
  color: black !important;
}

.MuiInputBase-input {
  padding: 12.5px 9px !important;
}

/* table */
.MuiSvgIcon-root.MuiSvgIcon-colorPrimary.MuiSvgIcon-fontSizeMedium {
  color: white;
}

.MuiTableRow-hover:hover {
  /* background-color: rgba(0, 0, 0, 0.21) !important; */
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.21) 0%, rgba(0, 0, 0, 0.1) 49%, rgba(0, 0, 0, 0.15) 80%, rgba(0, 0, 0, 0.21) 100%) !important;
  animation: slidebg 15s linear infinite !important;
}

@keyframes slidebg {
  to {
    background-position: 500vw;
  }
}

.base-Popper-root {
  /* transition: all 0s ease, background-position 1ms !important; */
}

.MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined.MuiInputAdornment-sizeMedium {
  margin-left: 0;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd {
  padding: 12.5px 0 12.5px 9px !important;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium {
  padding: 8px 8px 8px 8px;
}

.kindBoxCont {}

.kindBoxCont:hover {
  /* border: 1px solid #cccccc !important; */
  transition: box-shadow 111ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}